/* mixins */
/* Custom mixins */
/* inspired by bootstrap */
/******************************************************************************
						Home Page Slider
*******************************************************************************/
#homepage-slider {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 14px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  float: left;
  /*****************************************************************************
						BXSlider Styles
********************************************************************************/ }
  @media (max-width: 767px) {
    #homepage-slider {
      width: 100%;
      max-width: 100%;
      padding-top: 20px; } }
  #homepage-slider #homeslider {
    margin: 0 auto;
    padding: 0; }
  #homepage-slider .homeslider-container {
    position: relative; }
  #homepage-slider .homeslider-image {
    position: absolute;
    left: 0;
    top: 0; }
  #homepage-slider .homeslider-description {
    position: absolute;
    left: 0;
    top: 0;
    padding: 50px 50px;
    cursor: pointer; }
    #homepage-slider .homeslider-description h2 {
      font-size: 2.7em;
      color: white;
      margin: 0;
      padding: 0; }
    #homepage-slider .homeslider-description p {
      width: 50%;
      font-size: 1.15em;
      line-height: 1.3em;
      padding: 0.5em 0;
      color: white; }
    #homepage-slider .homeslider-description button {
      font-size: 1.2em;
      text-transform: uppercase; }
    @media (max-width: 1200px) {
      #homepage-slider .homeslider-description {
        padding: 20px;
        font-size: 0.8em; } }
    @media (max-width: 992px) {
      #homepage-slider .homeslider-description {
        padding: 30px;
        font-size: 0.7em; }
        #homepage-slider .homeslider-description p {
          font-size: 1.5em; } }
    @media (max-width: 768px) {
      #homepage-slider .homeslider-description {
        padding: 30px;
        font-size: 0.5em; }
        #homepage-slider .homeslider-description p {
          font-size: 1.7em; } }
  #homepage-slider .bx-wrapper a {
    display: block; }
  #homepage-slider .bx-wrapper img {
    height: auto; }
  #homepage-slider .bx-wrapper .bx-viewport {
    width: 100%;
    direction: ltr !important; }
  #homepage-slider .bx-wrapper .bx-pager.bx-default-pager a {
    background: #666; }
  #homepage-slider .bx-wrapper .bx-controls-direction a {
    top: 50%;
    margin-top: -17.5px;
    font-size: 1em;
    outline: 0;
    width: 35px;
    height: 35px;
    text-indent: -9999px;
    z-index: 9999;
    line-height: 18px;
    background-color: #454545;
    overflow: visible; }
    #homepage-slider .bx-wrapper .bx-controls-direction a:after {
      display: block;
      height: 35px;
      color: #fff;
      text-indent: 0;
      font-size: 18px;
      line-height: 35px;
      font-weight: 300;
      font-family: 'CRUX';
      text-shadow: 0 3px 3px rgba(51, 51, 51, 0.3);
      position: absolute;
      top: 0;
      z-index: 2; }
    #homepage-slider .bx-wrapper .bx-controls-direction a:hover:before {
      background: #333;
      opacity: 0.83; }
  @media (min-width: 1200px) {
    #homepage-slider .bx-wrapper .bx-prev {
      left: calc(50vw - 585px); }
      #homepage-slider .bx-wrapper .bx-prev:after {
        left: 15px;
        content: '\e027'; } }
  @media (max-width: 1199px) {
    #homepage-slider .bx-wrapper .bx-prev {
      left: calc(50vw - 45vw); }
      #homepage-slider .bx-wrapper .bx-prev:after {
        left: 15px;
        content: '\e027'; } }
  @media (max-width: 767px) {
    #homepage-slider .bx-wrapper .bx-prev {
      display: none; } }
  @media (min-width: 1200px) {
    #homepage-slider .bx-wrapper .bx-next {
      right: calc(50vw - 585px); }
      #homepage-slider .bx-wrapper .bx-next:after {
        right: 15px;
        content: '\e025'; } }
  @media (max-width: 1199px) {
    #homepage-slider .bx-wrapper .bx-next {
      right: calc(50vw - 45vw); }
      #homepage-slider .bx-wrapper .bx-next:after {
        right: 15px;
        content: '\e025'; } }
  @media (max-width: 767px) {
    #homepage-slider .bx-wrapper .bx-next {
      display: none; } }

.ie8 #homepage-slider .bx-wrapper .bx-controls-direction a:before {
  background: #333; }
